import {Observer} from 'mobx-react';

import {Button, BUTTON_STYLES} from '@/components/base/button/Button';
import {useAuthButtonActions} from '@/hooks/use-auth-button-actions';
interface TryButtonProps {
	hasDomainButtonText?: string;
	text?: string;
	fullWidth?: boolean;
	style?: BUTTON_STYLES;
	className?: string;
}

export function TryButton({
	hasDomainButtonText = 'Войти в личный кабинет',
	text = 'Попробовать',
	style,
	fullWidth,
	className,
}: TryButtonProps) {
	const {
		hasDomain,
		isAuth,
		linkToAdmin,
		featureService,
		handleOpenCreateProjectDialog,
		handleOpenLoginDialog,
		linkToAdminOnClick,
	} = useAuthButtonActions('tryBtn');

	if (isAuth) {
		if (hasDomain) {
			return (
				<Button
					onClick={linkToAdminOnClick}
					as="a"
					href={linkToAdmin}
					style={style}
					className={className}
				>
					{hasDomainButtonText}
				</Button>
			);
		}
		return (
			<Observer>
				{() => (
					<Button
						fullWidth={fullWidth}
						disabled={!featureService.isServiceStarted}
						onClick={handleOpenCreateProjectDialog}
						style={style}
						className={className}
					>
						{text}
					</Button>
				)}
			</Observer>
		);
	}

	return (
		<Button fullWidth={fullWidth} onClick={handleOpenLoginDialog} style={style} className={className}>
			{text}
		</Button>
	);
}
